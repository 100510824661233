var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticClass:"object_block"},[(_vm.data.title)?_c('span',{staticClass:"object_block__title"},[_vm._v(_vm._s(_vm.data.title))]):_vm._e(),_c('div',{staticClass:"object_block__subtitle"},[(_vm.data.payback)?_c('span',[_vm._v("Окупаемость:\n      "),(_vm.data.payback)?_c('span',{class:{
          'object_block__subtitle--red': _vm.data.payback < 51,
          'object_block__subtitle--yellow': _vm.data.payback > 50,
          'object_block__subtitle--green': _vm.data.payback > 89,
        }},[_vm._v(_vm._s(_vm.data.payback)+"%")]):_vm._e()]):_vm._e(),(_vm.data.left)?_c('span',[_vm._v("Осталось:\n      "),(_vm.data.left)?_c('span',{class:{
          'object_block__subtitle--red': _vm.data.left < 51,
          'object_block__subtitle--yellow': _vm.data.left > 50,
          'object_block__subtitle--green': _vm.data.left > 89,
        }},[_vm._v(_vm._s(_vm.data.left)+"%")]):_vm._e()]):_vm._e()]),_c('SliderComponent',{attrs:{"items":_vm.data.images,"slider-options":_vm.objectSliderOptions},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('router-link',{staticClass:"object_block__img",attrs:{"to":{ name: 'object', params: { id: _vm.data.id }  }}},[_c('ImgComponent',{attrs:{"head_img":item.img,"title":item.title}})],1)]}}],null,false,1306557920)}),(_vm.data.price)?_c('span',{staticClass:"object_block__price"},[_vm._v(" От "+_vm._s(_vm.data.price)+" ₽")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }